<template>
  <cs-page-setting name="password" ref="page">
    <a-form-model
      ref="form"
      key="form"
      :model="formModel"
      :rules="formRules"
      class="mx-auto"
      @submit.native.prevent
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item prop="passwordNew" label="输入新密码">
        <a-input
          v-model.trim="formModel.passwordNew"
          placeholder="密码长度在6-20个字符之间"
          :maxLength="20"
        />
      </a-form-model-item>
      <a-form-model-item prop="password" label="确认新密码">
        <a-input
          v-model.trim="formModel.password"
          placeholder="密码长度在6-20个字符之间"
          :maxLength="20"
        />
      </a-form-model-item>
      <a-button block type="primary" @click="nextClick">确定</a-button>
    </a-form-model>
  </cs-page-setting>
</template>
<script>
import { Base64 } from "js-base64";
import { mapState } from "vuex";
export default {
  data() {
    return {
      formModel: {
        passwordNew: "",
        password: "",
      },
      formRules: {
        password: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value && this.formModel.passwordNew != value) {
                return callback(new Error("两次输入密码不一致"));
              }
              callback();
            },
          },
        ],
        passwordNew: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.userInfo.userName == value) {
                return callback(new Error("密码不能和手机号相同"));
              }
              const errMsg = this.api.validate.password(value);
              if (errMsg) {
                return callback(new Error(errMsg));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ userInfo: (state) => state.store.userInfo }),
  },
  methods: {
    nextClick() {
      const { page } = this.$refs;
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          const { password } = this.formModel;
          this.api.service
            .uac_oapi_sso_user_updateUserPassword(
              {
                authCode: page.authCode,
                password: Base64.encode(password),
              },
              {}
            )
            .then((res) => {
              this.api.toast(res, 0).then(() => {
                page.next();
              });
            });
        }
      });
    },
  },
};
</script>

